export const adminUsersTableDataURL = "/users/team/user";
export const bluePrintsTableDataURL = "/programs/blueprint";
export const forgotPasswordURL = "users/forgot-password";
export const ForgotPasswordURL = "users/forgot-password";
export const OnBoardingGetAllPlanURL = "/plans";
export const OnBoardingPaymentURL = "/users/save-card";
export const OnBoardingSelectPlanURL = "/users/select-plan";
export const OnBoardingTabsDetailURL = "/users/tabs";
export const OnBoardingTermsAndConditionsURL = "/users/terms-conditions";
export const OnBoardingUserDetailsURL = "/users";
export const ResetPasswordURL = "users/reset-password";
export const SignInURL = "/users/login";
export const SignUpURL = "/users/sign-up";
export const UserDetailsURL = "/users/:id";
export const AddExerciseURL = "/exercises";
export const GetExercisesURL = "/exercises";
export const GetAdminApproveExerciseDataURL = "/exercises/details";
export const ApproveDeclineExerciseURL = "exercises/review-request";
export const AssignToClientsURL = "/programs";
export const GetBlueprintURL = "/programs/blueprint/:programId";
export const GetProgramURL = "/programs/:programId";
export const SaveAsBlueprintsURL = "/programs/blueprint";
export const GetUsersURL = "/clients/search";
export const BlueprintsAddImport = "/programs/add-import";
export const AddUserURL = "/users/team/user";
export const GetLicensesURL = "/users/team/user/licenses";
export const InactivateUserURL = "users/team/user/:id";
export const ActivateUserURL = "users/team/user/activate/:id";
export const GetUserDetailURL = "users/team/user/:id";
export const EditUserURL = "users/team/user/:id";
export const GetClientsURL = "";
export const GetClientsDataURL = "/clients";
export const AddClientsDataURL = "/clients";
export const GetClientsActiveProgramURL = "/clients/:clientId/programs/active";
export const StartClientSessionURL = "/clients/:clientId/sessions/start";
export const GetFormsDataURL = "/forms";
export const EditFormURL = "/forms/:formId";
export const GetFormURL = "/forms/:formId";
export const GetClientDetailURL = "/clients/:id";
export const GetFullClientOverviewDetailsURL = "/clients/:clientId/overview";
export const GetSubscriptionsDataURL = "/subscriptions";
export const GetAssessmentsURL = "/assessments";
export const GetAssessmentByIdURL = "/assessments/:id";
export const EditAssessmentURL = "/assessments/:id";
export const GetAssignToClientsCountURL = "/assessments/:id/assigned-clients";
export const AddNewAssessmentURL = "/assessments";
export const AddSubscriptionsDataURL = "/subscriptions";
export const GetSubscriptionDataById = "/subscriptions/:subscriptionId";
export const UpdateSubscriptionDataById = "/subscriptions/:subscriptionId";
export const GetServicesDataURL = "/services";
export const GetServiceDataById = "/services/:serviceId";
export const AddServiceDataURL = "/services";
export const UpdateServiceDataById = "/services/:serviceId";
export const AssignAssessmentToClients = "/assessments/:id/assign";
export const ExportBlueprintURL = "/programs/blueprint/export/:rowId";
export const GetImportBlueprintFieldsMappingsURL = "/programs/fields-mapping";
export const ImportBlueprintFileURL = "/programs/import";
export const GetAccountOwnerDetailsURL = "/settings/account";
export const ResetPasswordFromSettingsURL = "/settings/account/reset-password";
export const UpdateBillingURL = "/settings/account/update-billing";
export const GetCommunicationDataURL = "/settings/communication";
export const SaveCommunicationDetailsURL = "/settings/communication";
export const GetSetupDataURL = "/settings/setup";
export const SaveSetupDetailsURL = "/settings/setup";
export const GetTasksURL = "/tasks";
export const GetTaskByIdURL = "tasks/:taskId";
export const AddTaskURL = "/tasks";
export const GetMarketHubDataURL = "/markethubs";
export const EditProfileURL = "/settings/account";
export const GetAdminLandingPageDataURL = "/landing-page";
export const SaveAdminLandingPageDataURL = "/landing-page";
export const EditAdminLandingPageDataURL = "/landing-page";
export const GetLandingPageDataURL = "/landing-page/show";
export const SubmitUserResponseLandingPageURL = "/landing-page/submit-response";
export const AssignProgramToClientURL = "/clients/:id/assign-program";
export const GetPaymentCategoryListURL = "/subscriptions/payment-category";
export const GetCurrentProgramAndSubscriptionDataURL = "/clients/:clientId/current-program-subscription";
export const GetAllAssignedProgramAndSubscriptionDataURL = "/clients/:clientId/all-program-subscription";
export const GetClientViewProgramDetailsURL = "/clients/:clientId/assigned-program/:programId";
export const GetRecordSessionDetailsURL = "/clients/:clientId/sessions/:sessionId/details";
export const RecordSessionActionURL = "/clients/:clientId/sessions/:sessionId";
export const AddPaymentCategoryURL = "/subscriptions/payment-category";
export const AddClientsSubscriptionDataURL = "/clients/:id/subscription";
export const EditClientSubscriptionDataURL = "/clients/:clientId/subscription/:subscriptionId";
export const GetEditClientSubscriptionDataURL = "/clients/:clientId/subscription/:subscriptionId";
export const PayNowForSubscriptionURL = "clients/:clientId/subscriptions/:subscriptionId/pay-now";
export const AddBookingsURL = "/bookings";
export const GetServicesAndSubscriptionsDataURL = "/services/services-and-subscriptions";
export const GetBookingsByDateURL = "/bookings";
export const GetBookingByIdURL = "/bookings/:bookingId";
export const UpdateBookingStatusURL = "/bookings/:bookingId";
export const RescheduleBookingsURL = "/bookings/:bookingId/reschedule";
export const CancelBookingsURL = "/bookings/:bookingId";
export const GetUserAvailabilityURL = "/users/availability";
export const VerifyOTPURL = "users/team/user/verify-otp";
export const SetPasswordURL = "users/team/user/set-new-password";
export const UpdateClientDataURL = "clients/:clientId";
export const SetPaymentConnectionDataURL = "/settings/payment-connection";
export const GetPaymentConnectionStatusURL = "/settings/payment-connection/status";
export const GetPlanDetailsURL = "settings/account/change-plan/payment-schedule/";
export const ChangePlanURL = "/settings/account/change-plan";
export const FCMTokenURL = "/users/tokens";
export const GetCheckInFormsListURL = "/forms/check-in-forms-responses";
export const ScheduleCheckInFormURL = "/forms/schedule-check-in";
export const GetCheckInFormResponseURL = "forms/check-in-form-details";
export const AddBookingForClientURL = "/clients/:clientId/bookings";
export const GetUpcomingBookingsForClientURL = "/clients/:clientId/bookings";
export const GetNewLeadClientDetailsURL = "/clients/new-lead/:clientId";
export const InitiateNewLeadOnboardingURL = "clients/new-lead";
export const GetEngagementChartDataURL = "/clients/engagement-with-plan-chart";
export const GetSessionsChartDataURL = "/clients/sessions-chart";
export const GetMetricsChartDataURL = "/clients/metrics-charts";
export const GetAssessmentsChartDataURL = "/clients/line-chart-assessment";
export const GetSessionNotesListURL = "/clients/:clientId/sessions-notes";
export const GetSessionNoteDetailURL = "clients/:clientId/sessions/:sessionId/details";
export const AssignAssessmentToClientFromClientProfile = "clients/assign-assessment";
export const GetSessionFeedbackFormResponse = "clients/:clientId/sessions/:sessionId/feedback";
export const GetAssignedAssessmentDetailsURL = "clients/assigned-assessment";
export const RecordAssessmentURL = "clients/record-assessment";
export const ImportClientsFileURL = "/clients/import";
export const GetImportClientsFieldsMappingsURL = "/clients/fields-mapping";
export const ClientsAddImportURL = "/clients/add-import";
export const GetCoachListURL = "/users/team";
export const assignCoachURL = "clients/:clientId/reassign-coach";
export const GetDashboardOverviewCountsURL = "/dashboard/overview-counts";
export const GetDashboardPaymentsURL = "/dashboard/payments";
export const ExportPaymentDataURL = "/dashboard/export-payments";
export const GetClientEngagementDonutChartDataURL = "/dashboard/client-engagement-graph";
export const GetWorkoutCompletedDonutChartDataURL = "/dashboard/workout-completed-graph";
export const GetUpcomingSessionsDataURL = "/dashboard/upcoming-sessions";
export const GetAllNotificationsDataURL = "/dashboard/notifications";
export const ClearNotificationURL = "/dashboard/clear-notification";
export const MuteNotificationForTodayURL = "/dashboard/notification-mute-type";
export const MuteNotificationFromAllClientTodayURL = "/dashboard/notification-mute-client";
export const GetQuickFilterCountsURL = "/dashboard/payments/quick-filter";
