import { buildPath, buildPathForApi } from "./API";
import {
  ActivateUserURL,
  AddBookingForClientURL,
  AddBookingsURL,
  AddClientsDataURL,
  AddClientsSubscriptionDataURL,
  AddExerciseURL,
  AddNewAssessmentURL,
  AddPaymentCategoryURL,
  AddServiceDataURL,
  AddSubscriptionsDataURL,
  AddTaskURL,
  AddUserURL,
  adminUsersTableDataURL,
  ApproveDeclineExerciseURL,
  AssignAssessmentToClients,
  AssignProgramToClientURL,
  AssignToClientsURL,
  BlueprintsAddImport,
  bluePrintsTableDataURL,
  CancelBookingsURL,
  ChangePlanURL,
  EditAdminLandingPageDataURL,
  EditAssessmentURL,
  EditClientSubscriptionDataURL,
  EditProfileURL,
  EditUserURL,
  FCMTokenURL,
  ForgotPasswordURL,
  GetAccountOwnerDetailsURL,
  GetAdminApproveExerciseDataURL,
  GetAdminLandingPageDataURL,
  GetAllAssignedProgramAndSubscriptionDataURL,
  GetAssessmentByIdURL,
  GetAssessmentsURL,
  GetAssignToClientsCountURL,
  GetBlueprintURL,
  GetBookingByIdURL,
  GetBookingsByDateURL,
  GetCheckInFormResponseURL,
  GetCheckInFormsListURL,
  GetClientDetailURL,
  GetClientsActiveProgramURL,
  GetClientsDataURL,
  GetClientViewProgramDetailsURL,
  GetCommunicationDataURL,
  GetCurrentProgramAndSubscriptionDataURL,
  GetEditClientSubscriptionDataURL,
  GetExercisesURL,
  GetFormsDataURL,
  GetFormURL,
  GetFullClientOverviewDetailsURL,
  GetLandingPageDataURL,
  GetLicensesURL,
  GetMarketHubDataURL,
  GetPaymentCategoryListURL,
  GetPaymentConnectionStatusURL,
  GetPlanDetailsURL,
  GetProgramURL,
  GetRecordSessionDetailsURL,
  GetServiceDataById,
  GetServicesAndSubscriptionsDataURL,
  GetServicesDataURL,
  GetSetupDataURL,
  GetSubscriptionDataById,
  GetSubscriptionsDataURL,
  GetTaskByIdURL,
  GetTasksURL,
  GetUpcomingBookingsForClientURL,
  GetUserAvailabilityURL,
  GetUserDetailURL,
  GetUsersURL,
  InactivateUserURL,
  OnBoardingGetAllPlanURL,
  OnBoardingPaymentURL,
  OnBoardingSelectPlanURL,
  OnBoardingTabsDetailURL,
  OnBoardingTermsAndConditionsURL,
  OnBoardingUserDetailsURL,
  PayNowForSubscriptionURL,
  RecordSessionActionURL,
  RescheduleBookingsURL,
  ResetPasswordFromSettingsURL,
  ResetPasswordURL,
  SaveAdminLandingPageDataURL,
  SaveAsBlueprintsURL,
  SaveCommunicationDetailsURL,
  SaveSetupDetailsURL,
  ScheduleCheckInFormURL,
  SetPasswordURL,
  SetPaymentConnectionDataURL,
  SignInURL,
  SignUpURL,
  StartClientSessionURL,
  SubmitUserResponseLandingPageURL,
  UpdateBillingURL,
  UpdateBookingStatusURL,
  UpdateClientDataURL,
  UpdateServiceDataById,
  UpdateSubscriptionDataById,
  UserDetailsURL,
  VerifyOTPURL,
  GetNewLeadClientDetailsURL,
  InitiateNewLeadOnboardingURL,
  GetEngagementChartDataURL,
  GetSessionsChartDataURL,
  GetSessionNotesListURL,
  GetSessionNoteDetailURL,
  ExportBlueprintURL,
  GetImportBlueprintFieldsMappingsURL,
  ImportBlueprintFileURL,
  GetMetricsChartDataURL,
  AssignAssessmentToClientFromClientProfile,
  GetSessionFeedbackFormResponse,
  GetAssessmentsChartDataURL,
  RecordAssessmentURL,
  GetAssignedAssessmentDetailsURL,
  ImportClientsFileURL,
  GetImportClientsFieldsMappingsURL,
  ClientsAddImportURL,
  GetCoachListURL,
  assignCoachURL,
  GetDashboardOverviewCountsURL,
  GetDashboardPaymentsURL,
  ExportPaymentDataURL,
  GetClientEngagementDonutChartDataURL,
  GetWorkoutCompletedDonutChartDataURL,
  GetUpcomingSessionsDataURL,
  GetAllNotificationsDataURL,
  ClearNotificationURL,
  MuteNotificationForTodayURL,
  MuteNotificationFromAllClientTodayURL,
  GetQuickFilterCountsURL,
} from "./apiURLs";
import axiosInstance, { axiosFileUploadInstance } from "./axiosInstance";

export const AuthorizationActions = {
  signIn: (params) => axiosInstance.post(SignInURL, { ...params }),

  signUp: (params) => axiosInstance.post(SignUpURL, { ...params }),

  onBoardingTabsDetail: () => axiosInstance.get(OnBoardingTabsDetailURL),

  onBoardingUserDetails: (params) => axiosInstance.put(OnBoardingUserDetailsURL, { ...params }),

  onBoardingGetAllPlan: () => axiosInstance.get(OnBoardingGetAllPlanURL),

  onBoardingSelectPlan: (params) => axiosInstance.post(OnBoardingSelectPlanURL, { ...params }),

  onBoardingGetAllTermsAndConditions: () => axiosInstance.get(OnBoardingTermsAndConditionsURL),

  onBoardingTermsAndConditions: (params) => axiosInstance.put(OnBoardingTermsAndConditionsURL, { ...params }),

  onboardingPayment: (params) => axiosInstance.post(OnBoardingPaymentURL, { ...params }),

  userDetails: (id) => axiosInstance.get(buildPath(UserDetailsURL, { id })),

  forgotPassword: (params) => axiosInstance.post(ForgotPasswordURL, { ...params }),

  resetPassword: (params) => axiosInstance.post(ResetPasswordURL, { ...params }),

  verifyOTP: (params) => axiosInstance.post(VerifyOTPURL, { ...params }),

  setPassword: (params) => axiosInstance.post(SetPasswordURL, { ...params }),

  sendTokenToServer: (params) => axiosInstance.post(FCMTokenURL, { ...params }),

  deleteTokenFromServer: async (params) => axiosInstance.delete(FCMTokenURL, { data: params }),
};

export const BlueprintsActions = {
  getBlueprintsData: async (params) => axiosInstance.get(buildPathForApi(bluePrintsTableDataURL, params)),
  exportPDF: async (rowId, params) => {
    const dynamicRoute = buildPath(ExportBlueprintURL, { rowId });
    const mainUrl = buildPathForApi(dynamicRoute, { ...params });
    return axiosInstance.get(mainUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
  },
  exportExcel: (rowId, params) => {
    const dynamicRoute = buildPath(ExportBlueprintURL, { rowId });
    const mainUrl = buildPathForApi(dynamicRoute, { ...params });
    return axiosInstance.get(mainUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
  },
};

export const FormActions = {
  getFormsData: async (params) => axiosInstance.get(buildPathForApi(GetFormsDataURL, params)),

  createForm: (params) => axiosInstance.post(GetFormsDataURL, params),

  editForm: (params, formId) => axiosInstance.put(buildPath(GetFormURL, { formId }), { ...params }),

  getForm: (formId) => axiosInstance.get(buildPath(GetFormURL, { formId })),
};

export const AdminUsersActions = {
  getAdminUsersData: async (params) => axiosInstance.get(buildPathForApi(adminUsersTableDataURL, params)),
};

export const ExerciseActions = {
  addExercise: async (params) =>
    axiosInstance.post(AddExerciseURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  getExercises: async (params) => axiosInstance.get(buildPathForApi(GetExercisesURL, params)),

  getAdminApproveExerciseData: (params) =>
    axiosInstance.get(buildPathForApi(GetAdminApproveExerciseDataURL, { ...params })),

  approveDeclineExercise: (params) => axiosInstance.post(ApproveDeclineExerciseURL, { ...params }),
};

export const DynamicBuilderActions = {
  assignToClients: (params) => axiosInstance.post(AssignToClientsURL, { ...params }),

  getBlueprint: (programId) => axiosInstance.get(buildPath(GetBlueprintURL, { programId })),

  getProgram: (programId) => axiosInstance.get(buildPath(GetProgramURL, { programId })),

  saveAsBlueprints: (params) => axiosInstance.post(SaveAsBlueprintsURL, { ...params }),
};

export const BlueprintsImportActions = {
  importBlueprintsFile: (formData) => axiosFileUploadInstance.post(ImportBlueprintFileURL, formData),

  getBlueprintsData: (params) => axiosInstance.get(buildPathForApi(GetImportBlueprintFieldsMappingsURL, params)),

  importBlueprintAddProgram: (params) => axiosInstance.post(BlueprintsAddImport, params),
};

export const ClientActions = {
  importClientsFile: (formData) => axiosFileUploadInstance.post(ImportClientsFileURL, formData),

  getClientsFileFieldsData: (params) => axiosInstance.get(buildPathForApi(GetImportClientsFieldsMappingsURL, params)),

  importClients: (params) => axiosInstance.post(ClientsAddImportURL, params),

  getUsers: () => axiosInstance.get(GetUsersURL),

  addUser: (params) => axiosInstance.post(AddUserURL, { ...params }),

  editUser: (params, userId) => {
    const url = buildPath(EditUserURL, { id: userId });
    return axiosInstance.put(url, { ...params });
  },

  getLicensesDetails: () => axiosInstance.get(GetLicensesURL),

  inactivateUser: (userId) => axiosInstance.delete(buildPath(InactivateUserURL, { id: userId })),

  activateUser: (userId) => axiosInstance.put(buildPath(ActivateUserURL, { id: userId })),

  getUserById: (userId) => axiosInstance.get(buildPath(GetUserDetailURL, { id: userId })),

  getClientsData: async (params) => axiosInstance.get(buildPathForApi(GetClientsDataURL, params)),

  addClient: (params) => axiosInstance.post(AddClientsDataURL, { ...params }),

  getCheckInFormsList: (params) => axiosInstance.get(buildPathForApi(GetCheckInFormsListURL, { ...params })),

  scheduleCheckInForm: (params) => axiosInstance.post(ScheduleCheckInFormURL, { ...params }),

  getCheckInFormResponse: (params) => axiosInstance.get(buildPathForApi(GetCheckInFormResponseURL, { ...params })),
  GetClientsActiveProgram: (clientId, params) => {
    console.log(params);
    const url = buildPath(GetClientsActiveProgramURL, { clientId });
    return axiosInstance.get(buildPathForApi(url, { ...params }));
  },

  StartClientSession: (clientId, params) => {
    const url = buildPath(StartClientSessionURL, { clientId });
    return axiosInstance.post(url, params);
  },

  getNewLeadClientDetails: (clientId) => axiosInstance.get(buildPath(GetNewLeadClientDetailsURL, { clientId })),

  initiateNewLeadClient: (params) => axiosInstance.post(InitiateNewLeadOnboardingURL, { ...params }),
};

export const SubscriptionsActions = {
  getSubscriptions: () => axiosInstance.get(GetSubscriptionsDataURL),

  getSubscriptionsData: async (params) => axiosInstance.get(buildPathForApi(GetSubscriptionsDataURL, params)),

  getSubscriptionDataById: (subscriptionId) =>
    axiosInstance.get(buildPath(GetSubscriptionDataById, { subscriptionId })),

  updateSubscriptionDataById: (params, subscriptionId) => {
    const url = buildPath(UpdateSubscriptionDataById, { subscriptionId: subscriptionId });
    return axiosInstance.put(url, { ...params });
  },

  addSubscription: (params) => axiosInstance.post(AddSubscriptionsDataURL, { ...params }),

  getPaymentCategoryList: () => axiosInstance.get(GetPaymentCategoryListURL),

  addPaymentCategory: (params) => axiosInstance.post(AddPaymentCategoryURL, params),
};

export const ClientProfileActions = {
  getClientDetails: (clientId) => axiosInstance.get(buildPath(GetClientDetailURL, { id: clientId })),

  getBlueprintsData: async () => axiosInstance.get(buildPath(bluePrintsTableDataURL)),

  assignProgramToClient: (clientId, params) => {
    const url = buildPath(AssignProgramToClientURL, { id: clientId });
    return axiosInstance.post(url, { ...params });
  },

  getCurrentProgramAndSubscriptionData: (clientId) =>
    axiosInstance.get(buildPath(GetCurrentProgramAndSubscriptionDataURL, { clientId })),

  getAllAssignedProgramAndSubscriptionData: (clientId) =>
    axiosInstance.get(buildPath(GetAllAssignedProgramAndSubscriptionDataURL, { clientId })),

  getUpcomingBookingsForClient: (clientId) =>
    axiosInstance.get(buildPath(GetUpcomingBookingsForClientURL, { clientId })),

  GetClientViewProgramDetails: (clientId, programId) => {
    const url = buildPath(GetClientViewProgramDetailsURL, { clientId, programId });
    return axiosInstance.get(url);
  },

  GetRecordSessionDetails: (clientId, sessionId) => {
    const url = buildPath(GetRecordSessionDetailsURL, { clientId, sessionId });
    return axiosInstance.get(url);
  },

  RecordSessionAction: (clientId, sessionId, payload) => {
    const url = buildPath(RecordSessionActionURL, { clientId, sessionId });
    return axiosInstance.put(url, payload);
  },

  addClientSubscription: (clientId, params) => {
    const url = buildPath(AddClientsSubscriptionDataURL, { id: clientId });
    return axiosInstance.post(url, { ...params });
  },

  editClientSubscription: (clientId, subscriptionId, params) => {
    const url = buildPath(EditClientSubscriptionDataURL, { clientId, subscriptionId });
    return axiosInstance.put(url, { ...params });
  },
  getSubscriptionDataById: (clientId, subscriptionId) => {
    const url = buildPath(GetEditClientSubscriptionDataURL, { clientId, subscriptionId });
    return axiosInstance.get(url);
  },
  getClientDetials: (clientId) => axiosInstance.get(buildPath(GetClientDetailURL, { id: clientId })),

  getFullClientOverviewDetails: (clientId) =>
    axiosInstance.get(buildPath(GetFullClientOverviewDetailsURL, { clientId })),

  payNowForSubscription: (clientId, subscriptionId, params) => {
    const url = buildPath(PayNowForSubscriptionURL, { clientId, subscriptionId });
    return axiosInstance.post(url, { ...params });
  },

  updateClientDetails: (clientId, params) => {
    const url = buildPath(UpdateClientDataURL, { clientId });
    return axiosInstance.put(url, { ...params });
  },

  addBookingsForClient: async (clientId, params) => {
    const url = buildPath(AddBookingForClientURL, { clientId });
    return axiosInstance.post(url, { ...params });
  },

  getEngagementChartData: (params) => axiosInstance.get(buildPathForApi(GetEngagementChartDataURL, { ...params })),

  getSessionsChartData: (params) => axiosInstance.get(buildPathForApi(GetSessionsChartDataURL, { ...params })),

  getMetricsChartData: (params) => axiosInstance.get(buildPathForApi(GetMetricsChartDataURL, { ...params })),

  getAssessmentsChartData: (params) => axiosInstance.get(buildPathForApi(GetAssessmentsChartDataURL, { ...params })),

  getSessionNotes: async (clientId) => axiosInstance.get(buildPath(GetSessionNotesListURL, { clientId })),

  getSessionNoteDetails: async (clientId, sessionId) =>
    axiosInstance.get(buildPath(GetSessionNoteDetailURL, { clientId, sessionId })),

  assignAssessment: async (params) => axiosInstance.post(AssignAssessmentToClientFromClientProfile, { ...params }),

  getSessionFeedbackForm: async (clientId, sessionId) =>
    axiosInstance.get(buildPath(GetSessionFeedbackFormResponse, { clientId, sessionId })),

  getAssignedAssessmentDetails: async (params) =>
    axiosInstance.get(buildPathForApi(GetAssignedAssessmentDetailsURL, params)),

  recordAssessment: (params) =>
    axiosInstance.post(RecordAssessmentURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  getCoachList: () => axiosInstance.get(GetCoachListURL),

  assignCoachToClient: async (clientId, params) => {
    const url = buildPath(assignCoachURL, { clientId });
    return axiosInstance.post(url, { ...params });
  },
};

export const ServicesActions = {
  getServicesData: async (params) => axiosInstance.get(buildPathForApi(GetServicesDataURL, params)),

  getServiceDataById: (serviceId) => axiosInstance.get(buildPath(GetServiceDataById, { serviceId })),

  addService: (params) =>
    axiosInstance.post(AddServiceDataURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  updateServiceDataById: (params, serviceId) => {
    const url = buildPath(UpdateServiceDataById, { serviceId: serviceId });
    return axiosInstance.put(url, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};

export const AssessmentActions = {
  getAssessmentsData: async (params) => axiosInstance.get(buildPathForApi(GetAssessmentsURL, params)),

  getAssessmentById: async (assessmentId) => axiosInstance.get(buildPath(GetAssessmentByIdURL, { id: assessmentId })),

  addNewAssessment: async (params) => axiosInstance.post(AddNewAssessmentURL, { ...params }),

  assignAssessmentToClients: async (params, assessmentId) => {
    const url = buildPath(AssignAssessmentToClients, { id: assessmentId });
    return axiosInstance.post(url, { ...params });
  },

  getAssignToClientsCount: async (assessmentId) =>
    axiosInstance.get(buildPath(GetAssignToClientsCountURL, { id: assessmentId })),

  editAssesment: (params, assessmentId) => {
    const url = buildPath(EditAssessmentURL, { id: assessmentId });
    return axiosInstance.put(url, { ...params });
  },
};

export const SettingsActions = {
  getAccountOwnerDetails: async () => axiosInstance.get(GetAccountOwnerDetailsURL),

  resetAccountOwnerPassword: async (params) => axiosInstance.put(ResetPasswordFromSettingsURL, { ...params }),

  updateBilling: async (params) => axiosInstance.put(UpdateBillingURL, { ...params }),

  getCommunicationData: async () => axiosInstance.get(GetCommunicationDataURL),

  saveCommunicationDetails: async (params) => axiosInstance.post(SaveCommunicationDetailsURL, { ...params }),

  getSetupData: async () => axiosInstance.get(GetSetupDataURL),

  saveSetupDetails: async (params) => axiosInstance.post(SaveSetupDetailsURL, { ...params }),

  editProfile: async (params) =>
    axiosInstance.put(EditProfileURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  getPaymentConnectionStatus: async () => axiosInstance.get(GetPaymentConnectionStatusURL),

  setPaymentConnectionData: async (params) => axiosInstance.post(SetPaymentConnectionDataURL, { ...params }),

  getPlanDetails: async (params) => axiosInstance.get(buildPathForApi(GetPlanDetailsURL, params)),

  changePlan: async (params) => axiosInstance.post(ChangePlanURL, { ...params }),
};

export const TasksActions = {
  getAllTasksData: async (params) => axiosInstance.get(buildPathForApi(GetTasksURL, params)),

  getTaskById: async (taskId) => axiosInstance.get(buildPath(GetTaskByIdURL, { taskId })),

  addTask: async (params) => axiosInstance.post(AddTaskURL, { ...params }),
};

export const MarketHubActions = {
  getMarketHubData: async (params) => axiosInstance.get(buildPathForApi(GetMarketHubDataURL, params)),
};

export const AdminLandingPageActions = {
  getLandingPageData: async () => axiosInstance.get(GetAdminLandingPageDataURL),

  saveLadingPageData: async (params) =>
    axiosInstance.post(SaveAdminLandingPageDataURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),

  editLadingPageData: async (params) =>
    axiosInstance.put(EditAdminLandingPageDataURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
};

export const LandingPageActions = {
  getLandingPageData: async (params) => axiosInstance.get(buildPathForApi(GetLandingPageDataURL, params)),

  submitUserResponseLandingPageData: async (params) =>
    axiosInstance.post(SubmitUserResponseLandingPageURL, params, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
};

export const CalendarActions = {
  addBooking: (params) => axiosInstance.post(AddBookingsURL, { ...params }),

  getServicesAndSubscriptions: async (params) =>
    axiosInstance.get(buildPathForApi(GetServicesAndSubscriptionsDataURL, params)),

  getBookingsByDate: async (params) => axiosInstance.get(buildPathForApi(GetBookingsByDateURL, params)),

  getBookingsById: (bookingId) => axiosInstance.get(buildPath(GetBookingByIdURL, { bookingId })),

  updateBookingStatus: (params, bookingId) => {
    const url = buildPath(UpdateBookingStatusURL, { bookingId: bookingId });
    return axiosInstance.put(url, { ...params });
  },

  rescheduleBooking: (params, bookingId) => {
    const url = buildPath(RescheduleBookingsURL, { bookingId: bookingId });
    return axiosInstance.put(url, { ...params });
  },

  cancelBooking: (params, bookingId) => {
    const url = buildPath(CancelBookingsURL, { bookingId: bookingId });
    return axiosInstance.delete(url, { ...params });
  },

  getUserAvailability: async (params) => axiosInstance.get(buildPathForApi(GetUserAvailabilityURL, params)),
};

export const DashboardActions = {
  getDashboardOverviewCount: async (params) =>
    axiosInstance.get(buildPathForApi(GetDashboardOverviewCountsURL, params)),

  getDashboardPaymentsData: async (params) => axiosInstance.get(buildPathForApi(GetDashboardPaymentsURL, params)),

  exportPaymentPDF: async (params) => {
    const mainUrl = buildPathForApi(ExportPaymentDataURL, { ...params });
    return axiosInstance.get(mainUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      },
    });
  },

  exportPaymentExcel: (params) => {
    const mainUrl = buildPathForApi(ExportPaymentDataURL, { ...params });
    return axiosInstance.get(mainUrl, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    });
  },

  getAllNotificationsData: () => axiosInstance.get(GetAllNotificationsDataURL),

  clearNotification: (notificationId) => axiosInstance.delete(buildPathForApi(ClearNotificationURL, notificationId)),

  muteNotificationToday: (params) => axiosInstance.post(MuteNotificationForTodayURL, params),

  muteNotificationFromAllClientToday: (params) => axiosInstance.post(MuteNotificationFromAllClientTodayURL, params),

  getClientEngagementDonutChartData: async (params) =>
    axiosInstance.get(buildPathForApi(GetClientEngagementDonutChartDataURL, params)),

  getWorkoutCompletedDonutChartData: async (params) =>
    axiosInstance.get(buildPathForApi(GetWorkoutCompletedDonutChartDataURL, params)),

  getUpcomingSessionsDataURL: async (params) => axiosInstance.get(buildPathForApi(GetUpcomingSessionsDataURL, params)),

  getQuickFiltersCountURL: async (params) => axiosInstance.get(buildPathForApi(GetQuickFilterCountsURL, params)),
};

// if we want to pass params using buildPathForApi
//  get: (params, additionalConfig) => {
//     return axios.get(buildPathForApi(DashboardURL, params), additionalConfig);
//   },
