import moment from "moment-timezone";
import { excludedFeatures } from "../components/route/ExcludedFeatures";
import { routeConfig } from "../components/route/routeConfig";

export const ErrorMessage = (data) =>
  data?.message || data?.errors?.map((error) => error.message)?.join(", ") || data?.data?.message;

export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

export const renderSVGIcon = (src, alt) => <img src={src} alt={alt} width={24} height={24} />;

export const isRouteAllowed = (path, role) => {
  let isPublicRoute = false;
  let isPrivateRoute = false;

  if (routeConfig.authRoutes.some((regex) => regex.test(path))) {
    isPublicRoute = true;
  }

  if (role && routeConfig.privateRoutes[role]?.some((regex) => regex.test(path))) {
    isPrivateRoute = true;
  }

  return { isPublicRoute, isPrivateRoute };
};

export const isFeatureExcluded = (path) => path && excludedFeatures.some((regex) => regex.test(path));

export const stripHTMLTags = (html = "") => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const truncateText = (text = "", limit = 50) => {
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

export const containsScriptTag = (value) => {
  // Decode sanitized HTML entities
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(value, "text/html").body.textContent || "";
  // Check for <script> tags in the decoded string
  return /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi.test(decodedString);
};

// convert local date and time fields to UTC string
// date should be in YYYY-MM-DD and time should be in HH:mm
export const getUtcDateTimeString = (timezone, date, time) => {
  const localDateTime = `${date} ${time}`;
  const localDate = moment.tz(localDateTime, "YYYY-MM-DD HH:mm", timezone);
  const utcDate = localDate.utc();
  const formattedUtcTime = utcDate.format("YYYY-MM-DDTHH:mm:ss[Z]");
  return formattedUtcTime;
};

//convert UTC string to local date and time fields
export const convertUtcToLocalDateAndTime = (utcTime, timezone) => {
  const localDate = moment.utc(utcTime).tz(timezone);

  const formattedDate = localDate.format("YYYY-MM-DD");
  const formattedTime = localDate.format("HH:mm");

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

//splits UTC string into date and time
export const splitUtcStringIntoDateAndTime = (utcString) => {
  const momentUtc = moment.utc(utcString);
  const formattedDate = momentUtc.format("YYYY-MM-DD");
  const formattedTime = momentUtc.format("HH:mm");
  return {
    date: formattedDate,
    time: formattedTime,
  };
};

const getDayString = (differenceInDays) => {
  if (differenceInDays === 0) {
    return "Today";
  }
  if (differenceInDays === 1) {
    return "Yesterday";
  }
  return ` ${differenceInDays} days ago`;
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const compDate = new Date(dateString);
  compDate.setHours(0, 0, 0, 0);

  const now = new Date();

  const addLeadingZero = (num) => (num < 10 ? "0" + num : num);

  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());
  const hours = date.getHours() % 12 || 12;
  const minutes = addLeadingZero(date.getMinutes());
  const ampm = date.getHours() >= 12 ? "pm" : "am";

  const differenceInDays = Math.floor((now - compDate) / (1000 * 60 * 60 * 24));
  const dayString = getDayString(differenceInDays);

  const formattedDate = ` ${month}/${day} ${hours}:${minutes} ${ampm} ${dayString}`;

  return formattedDate;
};

//use this function to get last edited field string i.e 11/24 5:25 pm 5 Days to go
export const convertLastEditedField = (lastEdited, timezone) => {
  const { date, time } = convertUtcToLocalDateAndTime(lastEdited, timezone);
  const field = formatDateTime(`${date} ${time}:00`);
  return field;
};

export const calculateAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();

  // Adjust age if the birthday hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const formatTimeAgo = (timestamp) => {
  const now = Date.now();
  const time = new Date(timestamp).getTime();
  const seconds = Math.floor((now - time) / 1000);

  const intervals = [
    { label: "second", seconds: 1 },
    { label: "min", seconds: 60 },
    { label: "hour", seconds: 3600 },
    { label: "day", seconds: 86400 },
    { label: "week", seconds: 604800 },
    { label: "month", seconds: 2592000 },
    { label: "year", seconds: 31536000 },
  ];

  for (let i = intervals.length - 1; i >= 0; i--) {
    const interval = intervals[i];
    if (seconds >= interval.seconds) {
      const count = Math.floor(seconds / interval.seconds);
      return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }
  }

  return "Just Now";
};
